<template>
    <div id="receipt">
        <div class="admin_main_block">
            <div class="user_temp_title">包裹列表
               
            </div>
            <!-- <div class="admin_main_block_top clearfix">
                <div class="admin_main_block_left">
                    <div style="width:100px">
                        <el-select size="small" v-model="searchData.oldType" placeholder="选择新旧数据">
                            <el-option v-for="item in oldTypeList" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-input style="width:180px" size="small" placeholder="平台订单号" v-model="searchData.orderId"></el-input>
                    </div>
                    <div>
                        <el-input style="width:180px" size="small" placeholder="第三方单号" v-model="searchData.orderNo"></el-input>
                    </div>
                    <div>
                        <el-input style="width:180px" size="small" placeholder="快递单号" v-model="searchData.expressNo"></el-input>
                    </div>
                    <div>
                        <el-date-picker
                            style="width:200px"
                            v-model="time"
                            type="datetimerange"
                            size="small"
                            clearable
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"></el-date-picker>
                    </div>
                    <div style="width:195px;margin-top:10px">
                        <el-select size="small" v-model="searchData.status">
                            <el-option
                                v-for="item in statusList"
                                :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-input style="width:180px;margin-top:10px" size="small" placeholder="收件人手机号" v-model="searchData.phone" clearable></el-input>
                    </div>
                    <div>
                        <el-input style="width:180px;margin-top:10px" size="small" placeholder="收件人姓名" v-model="searchData.nameStr" clearable></el-input>
                    </div>
                    <div>
                        <el-input style="width:180px;margin-top:10px" size="small" placeholder="收件人详细地址" v-model="searchData.addressStr" clearable></el-input>
                    </div>
                    <div style="margin-top:10px">
                        <el-button icon="el-icon-search" type="primary" size="small" @click="search">查询</el-button>
                        <el-button type="primary" size="small" @click="exportExcel">导出表格</el-button>
                        <el-button type="primary" size="small" @click="exportPddExcel">导出拼多多表格</el-button>
                    </div>
                </div>
            </div> -->
            <div class="admin_table_main">
                <el-table size="small" :data="list" v-loading = 'loading'>
                    <el-table-column label="包裹状态">
                        <template slot-scope="scope">
                            <span v-for="item in statusList" v-if="scope.row.status==item.value">{{item.label}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="包裹号" prop="id"></el-table-column>
                    <el-table-column label="平台订单号" prop="order_id"></el-table-column>
                    <el-table-column label="第三方订单号" prop="order_no"></el-table-column>
                    <el-table-column label="总计费" prop="amount"></el-table-column>
                    <el-table-column label="收件人">
                        <template slot-scope="scope">
                            <div>
                                <p>
                                    <span>{{scope.row.name}}</span>
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="快递公司" prop="express_company"></el-table-column>
                    <el-table-column label="第三方订单号" prop="create_time"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="success" @click="detailPk(scope.row)">操作时间</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="home_fy_block">
                    <el-pagination
                        v-if="total>0"
                        background
                        @current-change="handleCurrentChange"
                        :current-page="searchData.pageNo"
                        :page-size="searchData.pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            title="包裹信息"
            :visible.sync="dialogVisible"
            width="40%">
            <div class="goodsInfo">
                <div class="img_wrap"><img :src="detailInfo.goodsInfo.cover | fullPath" alt=""></div>
                <div class="right">
                    <div class="text">
                        <p class="name" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><span>商品名：</span>{{detailInfo.goodsInfo.name}}</p>
                        <p><span>购买个数：</span>{{detailInfo.pkInfo.goodsNum}}</p>
                        <p><span>总重量：</span>{{detailInfo.pkInfo.weight}}</p>
                        <p><span>总价格: </span>{{detailInfo.pkInfo.amount}}</p>
                    </div>
                    <div class="text">
                        <p class="name"><span>收件人：</span>{{detailInfo.pkInfo.name}}</p>
                        <p><span>收件人电话：</span>{{detailInfo.pkInfo.phone}}</p>
                        <p><span>地址：</span>{{detailInfo.pkInfo.province}}{{detailInfo.pkInfo.city}}{{detailInfo.pkInfo.county}}{{detailInfo.pkInfo.address}}</p>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            time: [],
            statusList: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '待付款',
                    value: 0
                },
                {
                    label: '待发货',
                    value: 1
                },
                {
                    label: '已发货',
                    value: 2
                },
                // {
                //     label: '待退款',
                //     value: 3
                // },
                {
                    label: '已退款',
                    value: 4
                },
                // {
                //     label: '驳回退款',
                //     value: 5
                // },
                {
                    label: '交易成功',
                    value: 6
                },
            ],
            searchData:{
                pageNo:1,
                pageSize:10,
                status:'',
                id:"",
                orderId:'',
                orderNo:'',
                startTime:'',
                endTime:'',
                phone:'',
                nameStr:'',
                addressStr:'',
                oldType:1,
            },
            list:[],
            detailInfo:{
                pkInfo:{},
                goodsInfo:{},
            },//包裹详情
            dialogVisible:false,
            state: 1,
            total:0,
            loading:false,//防重复点击
            oldTypeList: [
                { value: 1, label: '新数据' },
                { value: 2, label: '旧数据' },
            ],
            expressSheetNameList: ['其他面单','通用面单','菜鸟面单','拼多多面单',"无界面单"],
        }
    },
    created(){
        var orderId = this.$route.query.orderId;
        if(orderId){
            this.searchData.orderId = orderId;
        }
        var oldType = this.$route.query.oldType;
        if(oldType){
            this.searchData.oldType = Number(oldType);
        }
        //this.getList();
        this.getNewList();
        this.getCount();
    },
    mounted(){},
    watch: {},
    methods: {
        exportExcel(){
            let _this = this
            if(this.loading) return
            this.loading = true
            let postData = this.$qs.stringify(this.searchData);
			this.$axios.post("/bus/pkInfo/exportList",postData,{
		        responseType: 'blob',
		    }).then(function(res) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(res.data);
                var filename = '包裹列表-' + _this.$time.getDate(new Date().getTime()) + '.xls';
                a.href = url;
                a.download = filename;
                a.click();
                _this.loading = false
                window.URL.revokeObjectURL(url);
            }).catch(function(error) {
                //consloe.log(error);
            })
        },
        exportPddExcel(){
            let _this = this
            if(this.loading) return
            this.loading = true
            let postData = this.$qs.stringify(this.searchData);
			this.$axios.post("/bus/pkInfo/exportPddList",postData,{
		        responseType: 'blob',
		    }).then(function(res) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(res.data);
                var filename = '拼多多包裹列表-' + _this.$time.getDate(new Date().getTime()) + '.xls';
                a.href = url;
                a.download = filename;
                a.click();
                _this.loading = false
                window.URL.revokeObjectURL(url);
            }).catch(function(error) {
                //consloe.log(error);
            })
        },
        search(){
            if((this.searchData.nameStr || this.searchData.addressStr) && (this.time.length==0 && !this.searchData.orderId)){
                this.$message.warning('选择收件人姓名或详细地址查询时请选择平台订单号或时间筛选');
                return;
            }
            this.searchData.pageNo = 1;
            //this.getList();
            this.getNewList();
            this.getCount();
        },
        getList(){
            if(this.time && this.time.length>0){
                this.searchData.startTime = this.$time.getDate(this.time[0]);
                this.searchData.endTime = this.$time.getDate(this.time[1]);
            }else{
                this.searchData.startTime = '';
                this.searchData.endTime = '';
            }
            this.loading = true;
            this.$request.post({
                url:'/bus/pkInfo/list',
                params:this.searchData,
                success: res => {
                    this.list = res.list;
                    this.total = res.total;
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
        getNewList(){
            if(this.time && this.time.length>0){
                this.searchData.startTime = this.$time.getDate(this.time[0]);
                this.searchData.endTime = this.$time.getDate(this.time[1]);
            }else{
                this.searchData.startTime = '';
                this.searchData.endTime = '';
            }
            this.loading = true;
            this.$request.post({
                url:'/ReportApi/business/pknewlist',
                params:this.searchData,
                success: result =>{
                    this.list = result
                },
                finally: ()=>{
                    this.loading = false
                }
            })
        },
        getCount() {
            if(this.time && this.time.length>0){
                this.searchData.startTime = this.$time.getDate(this.time[0]);
                this.searchData.endTime = this.$time.getDate(this.time[1]);
            }else{
                this.searchData.startTime = '';
                this.searchData.endTime = '';
            }
            this.$request.post({
                url: '/ReportApi/business/pklistcount',
                params: this.searchData,
                success: result =>{
                    this.total = result
                },
                finally: ()=>{
                }
            })
        },
        handleCurrentChange(val){
            this.searchData.pageNo = val;
            //this.getList();
            this.getNewList();
        },
        detailPk(row){
            console.log(row)
            if(this.loading) return;
            this.loading = true;
            this.detailInfo.pkInfo = row;
            this.$request.post({
                url:'/ReportApi/business/detail',
                params:{id:row.goods_id},
                success: res => {
                    this.detailInfo.goodsInfo = res[0];
                    this.dialogVisible = true
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
#receipt{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        min-height: 855px;
        border-radius: 4px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .admin_main_block_top{
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #efefef;
            .admin_main_block_left{
                float: left;
                div{
                    float: left;
                    margin-right: 15px;
                }
            }
        }
    }
    .el-table--mini, .el-table--small, .el-table__expand-icon {
        font-size: 12px;
    }
    .goodsInfo{
        display: flex;
        .img_wrap{
            width: 150px;
            height: 150px;
            img{
                width: 150px;
                display: block;
            }
        }
        .text{
            display: inline-block;
            margin-left: 20px;
            max-width: 300px;
            p{
                margin-bottom: 5px;
            }
        }
    }
    .home_fy_block{
        margin-top: 30px;
        text-align: right;
        margin-right: 10px;
    }
}
</style>
